import React from 'react';
import { ArcToolTip } from '@retisio/ui';
import SVG from 'react-inlinesvg';
import styles from './icon.module.scss';


function ArcIcon(props) {

  let { onClick, size, name, hover, className, text, format, icon } = props;
  
  size = {
    height: size?.height ? size.height : 16,
    width: size?.width ? size.width : 16
  };

  try {
    return (
      <ArcToolTip text={text}>
        <div className={`${styles.wrapper} ${(onClick || hover) ? styles.pointer : ""}
      ${className ? className : ''} `}
        onClick={onClick && onClick}>
          {/* <SVG src={`${process.env.PUBLIC_URL}/images/${name}.svg`}
            width={size.width} height={size.height} title={name} /> */}
          {
            format === 'png' || format === 'jpg' || format === 'jpeg' ?
              // eslint-disable-next-line jsx-a11y/alt-text
              <img src={`${process.env.PUBLIC_URL}/images/${name}.${format}`}
                width={props.size.width}
                height={props.size.height}
                alt={name}
                className={className ? className : ''} /> :
              <SVG src={`${process.env.PUBLIC_URL}/${icon ? 'icons' : 'images'}/${name}.svg`}
                width={size.width} height={size.height} title={name} />
          }
        </div>
      </ArcToolTip>
    );
  } catch (e) {
    let err = `Icon Error: ${(!name) ? 'name is missing in prop' : e.message}`;
    return <span title={err} >icon error</span>;
  }
}

export default ArcIcon;